footer {
  bottom: 5%;
  width: 100%;
}

.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.navbarButton {
  color: #fff !important;
  padding: 0.5rem 1rem !important;
}

.navbarStyle {
  padding: 0.5rem 1rem !important;
}

.table-responsive {
  max-height: 39rem !important;
}

.tableColumn {
  word-break: break-all;
}

.profileButton {
  margin: 0.5rem 0.5rem;
}

body {
  background-color: #f7f9fa;
  color: #5b626b;
}

a {
  color: #6c757d;
}

.circleForInitials {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  padding: 10px;
  background: #5b626b;
  color: #f7f9fa;
  text-align: center;
  font: 16px;
  cursor: pointer;
}

.circleForInitialsBigger {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  padding: 20px;
  background: #5b626b;
  color: #f7f9fa;
  text-align: center;
}

/* Fix: main body containers were preventins mouse pointer events on items below them */

#mainCenterContainer {
  pointer-events: none;
}

#mainRowContainer {
  pointer-events: none;
}

.col-auto {
  pointer-events: auto !important;
}
